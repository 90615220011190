import styled from "styled-components";

const H1 = styled.h1`
  margin: 10px auto;
  text-align: center;
  font-family: "Gotham-Medium";
  font-size: 12px;
  color: white;
  line-height: 150%;
`;

export default function Headline() {
  return (
    <>
      <H1>
        <span style={{ fontSize: '25px', textDecoration: 'underline rgb(71, 132, 237)', margin: '5px ', display: 'inline-block' }}>RASPE</span>
        <br />
        <span style={{ color: '#4784ED', fontSize: '12px', margin: '0 5px' }}>1 RASPADINHA</span>
        PARA SE DIVERTIR COM
        <span style={{ color: '#4784ED', fontSize: '12px', margin: '0 5px' }}>GIROS</span>
      </H1>
    </>
  );
}
