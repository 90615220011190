import React from "react";
import { NonOfferItem, NormalOffer, PrincipalOffer } from "./styles";

interface OfferProps {
    children: React.ReactNode;
    token?: string | null;
    isOffer?: boolean;
    isPrincipalOffer?: boolean;
    className?: string;
}

export const Offer: React.FC<OfferProps> = ({ children, token, isPrincipalOffer, isOffer, className }) => {
    if (isPrincipalOffer) {
        return (
            <PrincipalOffer href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwaipush`} className={className && className}>
                {children}
            </PrincipalOffer>
        );
    } else if (isOffer) {
        return (
            <NormalOffer href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwaipush`}>
                {children}
            </NormalOffer>
        );
    } else {
        return (
            <NonOfferItem href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwaipush`}>
                {children}
            </NonOfferItem>
        );
    }
};