import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style.css";
import Card from "../scratch/Card";
import { usePush } from "../hook/usePush";
import { Fade } from "react-awesome-reveal";

// Modals
import ModalWin from "../modal-win/Modal";
import { Responsibility } from "../Responsibility/Responsibility";

interface ModalProps {
    token?: string | null;
    showModal: boolean;
}

const CardComponent = styled.div`
    display: block;
    border: transparent;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        // align-items: center;
        height: -webkit-fill-available;
        overflow: hidden;
    }
`

const ModalComponent = styled.div`
    background-color: #232426;
    padding: 20px 10px;
    border-radius: 20px;
    border: 4px solid #0092ff;
`

const Modal: React.FC<ModalProps> = ({ token, showModal }) => {
    const { permission, requestPermission } = usePush() || {};

    const [scratch, setScratch] = useState<boolean>(true);

    // Modals
    const [modalWin, setModalWin] = useState<boolean>(false);

    useEffect(() => {
        if (showModal) {
            setModalWin(true);
            setScratch(false);
        }
    }, [showModal]);

    useEffect(() => {
        requestPermission!();
    }, [permission])

    return (
        <>
            <iframe
                src="https://cassinopix.com/"
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    opacity: 1,
                    filter: "blur(2px)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            ></iframe>
            <Responsibility />
            {scratch && (
                <div id="modal">
                    <CardComponent>
                        <Card />
                    </CardComponent>
                </div>
            )}
            {modalWin && (
                <Fade>
                    <div id="modal">
                        <ModalComponent>
                            <ModalWin token={token} />
                        </ModalComponent>
                    </div>
                </Fade >
            )}
        </>
    );
};

export default Modal;